import React from "react"
import { useStaticQuery, graphql } from "gatsby"

//import { className, Heading, Text } from '@solublestudio/dozen-design-system';

import Section from "../../sections"

//import style from './style.module.scss';

export default function BlogFooterComponent() {
    const data = useStaticQuery(graphql`
        query {
            blogPage: datoCmsPage(isBlogPage: { eq: true }) {
                sections {
                    ... on DatoCmsCtaDouble {
                        __typename
                        title1
                        text1Node {
                            ...TextHtml
                        }
                        buttons1 {
                            ...Button
                        }
                        title2
                        text2Node {
                            ...TextHtml
                        }
                        buttons2 {
                            ...Button
                        }
                    }
                }
            }
        }
    `)

    const finalSections = data.blogPage.sections.filter(
        (s) => s?.__typename && s?.__typename?.includes("Blog")
    )

    return (
        <>
            {finalSections.map((section, i) => (
                <Section
                    key={i}
                    order={2}
                    isLast={i + 1 === finalSections.length}
                    {...section}
                />
            ))}
        </>
    )
}
