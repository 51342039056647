import React, { useEffect, useState, useMemo } from "react"
import { graphql } from "gatsby"
import {
    Link,
    Layout,
    useTranslate,
} from "@solublestudio/gatsby-theme-soluble-source"
import { fetchExternalBlogData } from "../fetch"
import { Breadcrumb, className } from "@solublestudio/dozen-design-system"

import BlogPageSection from "../components/BlogPageSection"
import BlogFooterComponent from "../components/BlogFooterComponent"

const getSearchParam = () => {
    if (typeof window === "undefined") {
        return ""
    }

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    return urlParams.get("q")
}

export default function BlogSearch({ data }) {
    const translation = useTranslate()

    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [searchParam, setSearchParam] = useState("")

    useEffect(() => {
        if (typeof window === "undefined" || !searchParam) {
            return
        }

        fetchExternalBlogData(null, searchParam).then((newItems) => {
            setItems(newItems)
            setLoading(false)
        })
    }, [setItems, setLoading, searchParam])

    useEffect(() => {
        setSearchParam(getSearchParam())
    }, [setSearchParam])

    const [title, titleSeo] = useMemo(() => {
        const title = translation["blog.search.title"].text.replace(
            "{term}",
            searchParam
        )
        const titleSeo = title.replace(/&quot;/gm, '"')

        return [title, titleSeo]
    }, [searchParam, translation])

    return (
        <Layout
            navbarFixed={true}
            navbarTransparent={true}
            isDark={false}
            customSeo={
                data.blogPage && data.blogPage.seo
                    ? {
                          title: titleSeo,
                          description: data.blogPage.seo.description,
                          image:
                              data.blogPage.seo.image &&
                              data.blogPage.seo.image.file
                                  ? data.blogPage.seo.image.file
                                  : null,
                          noIndex: true,
                          removeSuffix: false,
                          alternateLanguages: [],
                      }
                    : null
            }
        >
            <BlogPageSection
                title={title}
                loading={loading}
                posts={items}
                text={
                    !items.length && !loading
                        ? translation["blog.search.noresults"].md
                        : null
                }
                pretitleContent={
                    <Breadcrumb
                        wrapperClassName={className("mb-3 mb-lg-4").className}
                        links={[
                            {
                                Tag: Link,
                                scheme: "gatsby",
                                href: `/${data.blogPage.slug}/`,
                                title: data.blogPage.title,
                            },
                            {
                                title: searchParam,
                            },
                        ]}
                    />
                }
            />
            <BlogFooterComponent />
        </Layout>
    )
}

export const query = graphql`
    query BlogSearch {
        blogPage: datoCmsPage(isBlogPage: { eq: true }) {
            title
            slug
            seo {
                description
                title
                image {
                    file {
                        childImageSharp {
                            resize(width: 1200, height: 630) {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
`
