import React from "react"
import { Link } from "gatsby"
import { chunkArray } from "../../utils"

import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"
import {
    Container,
    Row,
    Col,
    SectionComponent,
    HeadingBold,
    TextComponent,
    LineComponent,
} from "@solublestudio/dozen-design-system"

import CardBlogComponent from "../CardBlogComponent"
import ShowMoreComponent from "../ShowMoreComponent"

import style from "./style.module.scss"

export default function BlogSection({
    title,
    text = null,
    posts = [],
    previousPage = null,
    nextPage = null,
    loading = false,
    headingTag = "h1",
    blogPostPage = false,
    pretitleContent,
}) {
    const translation = useTranslate()

    return (
        <SectionComponent
            className={`pt-4 pt-lg-${blogPostPage ? 20 : 9} pb-${
                nextPage || previousPage || (!loading && !posts.length) ? 12 : 6
            } pb-lg-0`}
        >
            <LineComponent up={true} className={style.line} />
            <Container>
                <Row pb={{ xs: headingTag !== "h6" ? 6 : 0, lg: 5 }}>
                    <Col col={{ lg: 8 }} offset={blogPostPage ? { lg: 2 } : {}}>
                        {pretitleContent ? pretitleContent : null}
                        <HeadingBold tag={headingTag} className="d3">
                            {title}
                        </HeadingBold>
                        {text ? <TextComponent>{text}</TextComponent> : null}
                    </Col>
                </Row>
                {posts && posts.length
                    ? chunkArray(posts, 3).map((thisPosts, i) => (
                          <Row
                              key={i}
                              className="d-lg-flex align-items-lg-stretch"
                          >
                              {thisPosts.map((post, ii) => (
                                  <Col key={ii} col={{ lg: 4 }} pb={{ xs: 6 }}>
                                      <CardBlogComponent key={i} {...post} />
                                  </Col>
                              ))}
                          </Row>
                      ))
                    : null}
                {loading ? (
                    <Row>
                        <Col pt={{ xs: 4 }} pb={{ xs: 6 }}>
                            <ShowMoreComponent
                                loading={true}
                                onClick={() => {}}
                            />
                        </Col>
                    </Row>
                ) : null}
                {nextPage || previousPage ? (
                    <Row>
                        <Col col={{ xs: 6 }}>
                            <Link
                                className={`${style.pagination} ${style.previous}`}
                                to={`/recursos/${
                                    previousPage === 1 ? "" : `${previousPage}/`
                                }`}
                            >
                                {translation["blog.page.previous"].text}
                            </Link>
                        </Col>
                        <Col className="text-right" col={{ xs: 6 }}>
                            {nextPage ? (
                                <Link
                                    className={`${style.pagination}`}
                                    to={`/recursos/${nextPage}/`}
                                >
                                    {translation["blog.page.next"].text}
                                </Link>
                            ) : null}
                        </Col>
                    </Row>
                ) : null}
            </Container>
        </SectionComponent>
    )
}
